type SVG = {
  className?: string
  linkColor?: string
}

export const CheckCircle = ({ className }: SVG): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    className={className}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
)
export const CheckCircleFilled = ({ className }: SVG): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
      clipRule="evenodd"
    />
  </svg>
)

export const AcademicCap = ({ className }: SVG): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    className={className}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
    />
  </svg>
)

export const Quiz = ({ className, linkColor }: SVG) => (
  <svg
    className={className}
    viewBox="0 0 310 420"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <path
      className="fill-current"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M117.072 156.803C109.967 151.778 100.898 150.745 92.8146 154.039L88.5681 155.77L84.8283 153.126C84.8206 153.121 84.8134 153.116 84.8057 153.11C74.3529 145.718 71.8219 131.183 79.1656 120.695L119.357 63.3003L177.586 103.961C179.494 105.305 181.882 105.482 183.891 104.639C180.926 92.81 183.204 80.2778 190.371 70.1442C197.08 60.6569 207.107 54.4975 218.604 52.799C230.097 51.1007 241.481 54.1103 250.674 61.2655C267.757 74.5763 271.889 99.2287 260.081 117.389C253.052 128.202 241.405 135.145 228.746 136.373C228.603 138.546 229.544 140.745 231.426 142.11L288.999 182.208L169.139 351.689L0.67534 232.549L41.0294 174.786C48.3273 164.338 62.8183 161.702 73.3044 168.891L77.2947 171.71L77.0778 176.29C76.6464 185.417 80.9639 193.831 88.6147 198.816C99.5521 205.927 114.397 203.439 122.412 193.153C126.731 187.608 128.547 180.741 127.524 173.817C126.501 166.889 122.789 160.846 117.072 156.803ZM135.615 203.44C122.305 220.525 97.6523 224.657 79.4921 212.849C68.7009 205.833 61.7593 194.211 60.5146 181.576C58.3124 181.458 56.0972 182.443 54.7499 184.371L23.9268 228.493L165.138 328.359L265.598 186.308L221.805 155.807L221.718 155.745C211.34 148.318 208.893 133.798 216.261 123.379L218.943 119.587L223.523 119.803C232.651 120.235 241.071 115.922 246.049 108.266C253.16 97.3292 250.672 82.4842 240.386 74.4695C234.845 70.1528 227.975 68.3335 221.051 69.3565C214.121 70.3804 208.079 74.092 204.036 79.8086C199.011 86.9135 197.978 95.9822 201.272 104.067L203.002 108.313L200.355 112.057C192.956 122.519 178.416 125.025 167.973 117.663L123.479 86.5931L92.8755 130.295C91.5251 132.224 91.3583 134.638 92.2227 136.663C104.058 133.691 116.597 135.968 126.736 143.138C136.223 149.848 142.383 159.874 144.082 171.372C145.781 182.864 142.774 194.253 135.615 203.44Z"
      fill={linkColor}
    />
  </svg>
)

export const FilledRightArrow = ({ className, linkColor }: SVG) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 12"
    stroke="currentColor"
  >
    <path
      className="fill-current"
      fill={linkColor}
      d="M9.5 5.134a1 1 0 010 1.732L2 11.196a1 1 0 01-1.5-.866V1.67A1 1 0 012 .804l7.5 4.33z"
    ></path>
  </svg>
)

export const Trophy = ({ className }: SVG) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 512 512"
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={36}
      d="M176 464h160M256 464V336M384 224c0-50.64-.08-134.63-.12-160a16 16 0 00-16-16l-223.79.26a16 16 0 00-16 15.95c0 30.58-.13 129.17-.13 159.79 0 64.28 83 112 128 112S384 288.28 384 224z"
    />
    <path
      d="M128 96H48v16c0 55.22 33.55 112 80 112M384 96h80v16c0 55.22-33.55 112-80 112"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={36}
    />
  </svg>
)

export const Idea = ({ className }: SVG) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={className}
  >
    <path
      d="M304 384v-24c0-29 31.54-56.43 52-76 28.84-27.57 44-64.61 44-108 0-80-63.73-144-144-144a143.6 143.6 0 00-144 144c0 41.84 15.81 81.39 44 108 20.35 19.21 52 46.7 52 76v24M224 480h64M208 432h96M256 384V256"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
    <path
      d="M294 240s-21.51 16-38 16-38-16-38-16"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
  </svg>
)

export const OpenBook = ({ className }: SVG) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
    />
  </svg>
)

export const ClipBoardList = ({ className }: SVG) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
    />
  </svg>
)

// Social icon components -
// All the below SVGs are from https://simpleicons.org/
export const Youtube = ({ className }: SVG) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
    fill="currentColor"
  >
    <path d="M23.498 6.186a3.016 3.016 0 00-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 00.502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 002.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 002.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"></path>
  </svg>
)

export const Twitter = ({ className }: SVG) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className={className}
  >
    <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"></path>
  </svg>
)

export const Instagram = ({ className }: SVG) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    className={className}
  >
    <path d="M9.998 6.665A3.342 3.342 0 006.663 10a3.342 3.342 0 003.335 3.335A3.342 3.342 0 0013.332 10a3.342 3.342 0 00-3.335-3.335zM19.998 10c0-1.38.013-2.75-.065-4.128-.077-1.6-.443-3.022-1.613-4.193C17.147.506 15.729.143 14.128.066 12.747-.012 11.378 0 10 0 8.62 0 7.25-.012 5.872.066 4.272.143 2.85.509 1.68 1.679.506 2.853.143 4.271.066 5.872-.012 7.253 0 8.622 0 10c0 1.378-.013 2.75.065 4.128.077 1.6.443 3.022 1.613 4.193 1.174 1.173 2.592 1.536 4.193 1.613C7.253 20.012 8.622 20 10 20c1.38 0 2.75.013 4.128-.065 1.6-.077 3.022-.443 4.193-1.613 1.173-1.174 1.536-2.592 1.613-4.193.08-1.379.065-2.747.065-4.128zM9.998 15.131A5.124 5.124 0 014.867 10a5.124 5.124 0 015.13-5.131A5.124 5.124 0 0115.13 10a5.124 5.124 0 01-5.131 5.131zm5.34-9.274A1.197 1.197 0 0114.14 4.66c0-.663.536-1.198 1.199-1.198a1.197 1.197 0 01.847 2.046 1.196 1.196 0 01-.847.35z"></path>
  </svg>
)

export const LinkedIn = ({ className }: SVG) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className={className}
  >
    <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433a2.062 2.062 0 01-2.063-2.065 2.064 2.064 0 112.063 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"></path>
  </svg>
)

export const Facebook = ({ className }: SVG) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className={className}
  >
    <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"></path>
  </svg>
)

export const Puzzle = ({ className }: SVG) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
    />
  </svg>
)
export const TrustpilotRating = ({ className }: SVG) => (
  <svg
    width="105"
    height="20"
    className={className}
    viewBox="0 0 105 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_273_22104)">
      <path
        d="M20.3436 0.271484H0.946899V19.2724H20.3436V0.271484Z"
        fill="#00B67A"
      />
      <path
        d="M41.3567 0.271484H21.96V19.2724H41.3567V0.271484Z"
        fill="#00B67A"
      />
      <path
        d="M62.3699 0.271484H42.9731V19.2724H62.3699V0.271484Z"
        fill="#00B67A"
      />
      <path
        d="M83.3831 0.271484H63.9863V19.2724H83.3831V0.271484Z"
        fill="#00B67A"
      />
      <path
        d="M104.396 0.271484H94.6978V19.2724H104.396V0.271484Z"
        fill="#DCDCE6"
      />
      <path
        d="M97.2522 0.271484H84.9994V19.2724H97.2522V0.271484Z"
        fill="#00B67A"
      />
      <path
        d="M10.6453 13.0774L13.5952 12.3451L14.8277 16.0661L10.6453 13.0774ZM17.4342 8.26783H12.2415L10.6453 3.47803L9.04911 8.26783H3.85645L8.05907 11.2367L6.46288 16.0265L10.6655 13.0576L13.2517 11.2367L17.4342 8.26783Z"
        fill="white"
      />
      <path
        d="M31.6584 13.0774L34.6083 12.3451L35.8408 16.0661L31.6584 13.0774ZM38.4472 8.26783H33.2545L31.6584 3.47803L30.0622 8.26783H24.8695L29.0721 11.2367L27.4759 16.0265L31.6786 13.0576L34.2648 11.2367L38.4472 8.26783Z"
        fill="white"
      />
      <path
        d="M52.6715 13.0774L55.6215 12.3451L56.854 16.0661L52.6715 13.0774ZM59.4604 8.26783H54.2677L52.6715 3.47803L51.0754 8.26783H45.8827L50.0853 11.2367L48.4891 16.0265L52.6918 13.0576L55.278 11.2367L59.4604 8.26783Z"
        fill="white"
      />
      <path
        d="M73.6846 13.0774L76.6345 12.3451L77.867 16.0661L73.6846 13.0774ZM80.4735 8.26783H75.2808L73.6846 3.47803L72.0884 8.26783H66.8958L71.0984 11.2367L69.5022 16.0265L73.7048 13.0576L76.291 11.2367L80.4735 8.26783Z"
        fill="white"
      />
      <path
        d="M94.6978 13.0774L97.6477 12.3451L98.8802 16.0661L94.6978 13.0774ZM101.487 8.26783H96.294L94.6978 3.47803L93.1016 8.26783H87.9089L92.1116 11.2367L90.5154 16.0265L94.718 13.0576L97.3042 11.2367L101.487 8.26783Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_273_22104">
        <rect
          width="103.449"
          height="19.0009"
          fill="white"
          transform="translate(0.946899 0.271484)"
        />
      </clipPath>
    </defs>
  </svg>
)

export const Certificate = ({ className }: SVG) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="27"
    fill="none"
    viewBox="0 0 20 27"
    className={className}
  >
    <path
      fill="#fff"
      stroke="#000"
      strokeMiterlimit="10"
      d="M19 21.366H1V1h14.348L19 4.211v17.155z"
    ></path>
    <path
      stroke="#000"
      strokeMiterlimit="10"
      d="M3.696 4.634h6M3.696 7.253h12.696M3.696 10.38h12.696"
    ></path>
    <path
      fill="#fff"
      stroke="#000"
      strokeMiterlimit="10"
      d="M11.348 25l-1.74-1.69L7.87 25v-7.014h3.478V25z"
    ></path>
    <path
      fill="#fff"
      stroke="#000"
      strokeMiterlimit="10"
      d="M9.608 20.352c2.21 0 4-1.74 4-3.887 0-2.147-1.79-3.887-4-3.887-2.209 0-4 1.74-4 3.887 0 2.147 1.791 3.887 4 3.887z"
    ></path>
  </svg>
)

export const Stars = ({ className }: SVG) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="91"
    height="17"
    fill="none"
    viewBox="0 0 91 17"
    className={className}
  >
    <path
      fill="#FFB000"
      d="M8.5.001l2.497 5.061 5.586.812-4.042 3.94.954 5.562L8.5 12.75l-4.995 2.626.954-5.562-4.042-3.94 5.585-.812L8.5.001z"
    ></path>
    <mask
      id="mask0_2920_42712"
      style={{ maskType: 'alpha' }}
      width="17"
      height="16"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#FFB000"
        d="M8.5.001l2.497 5.061 5.586.812-4.042 3.94.954 5.562L8.5 12.75l-4.995 2.626.954-5.562-4.042-3.94 5.585-.812L8.5.001z"
      ></path>
    </mask>
    <g mask="url(#mask0_2920_42712)">
      <ellipse
        cx="5.559"
        cy="5.666"
        fill="url(#paint0_linear_2920_42712)"
        rx="5.568"
        ry="7.904"
        transform="rotate(36.687 5.56 5.666)"
      ></ellipse>
    </g>
    <path
      fill="#FFB000"
      d="M26.806 0l2.498 5.061 5.585.812-4.041 3.94.954 5.562-4.996-2.626-4.996 2.626.954-5.562-4.041-3.94 5.585-.812L26.806 0z"
    ></path>
    <mask
      id="mask1_2920_42712"
      style={{ maskType: 'alpha' }}
      width="17"
      height="16"
      x="18"
      y="0"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#FFB000"
        d="M26.806 0l2.498 5.061 5.585.812-4.041 3.94.954 5.562-4.996-2.626-4.996 2.626.954-5.562-4.041-3.94 5.585-.812L26.806 0z"
      ></path>
    </mask>
    <g mask="url(#mask1_2920_42712)">
      <ellipse
        cx="23.866"
        cy="5.666"
        fill="url(#paint1_linear_2920_42712)"
        rx="5.568"
        ry="7.904"
        transform="rotate(36.687 23.866 5.666)"
      ></ellipse>
    </g>
    <path
      fill="#FFB000"
      d="M45.112 0l2.497 5.061 5.586.812-4.042 3.94.954 5.562-4.995-2.626-4.996 2.626.954-5.562-4.042-3.94 5.586-.812L45.112 0z"
    ></path>
    <mask
      id="mask2_2920_42712"
      style={{ maskType: 'alpha' }}
      width="17"
      height="16"
      x="37"
      y="0"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#FFB000"
        d="M45.112 0l2.497 5.061 5.586.812-4.042 3.94.954 5.562-4.995-2.626-4.996 2.626.954-5.562-4.042-3.94 5.586-.812L45.112 0z"
      ></path>
    </mask>
    <g mask="url(#mask2_2920_42712)">
      <ellipse
        cx="42.172"
        cy="5.666"
        fill="url(#paint2_linear_2920_42712)"
        rx="5.568"
        ry="7.904"
        transform="rotate(36.687 42.172 5.666)"
      ></ellipse>
    </g>
    <path
      fill="#FFB000"
      d="M63.418 0l2.498 5.061 5.585.812-4.041 3.94.954 5.562-4.996-2.626-4.996 2.626.954-5.562-4.041-3.94 5.585-.812L63.418 0z"
    ></path>
    <mask
      id="mask3_2920_42712"
      style={{ maskType: 'alpha' }}
      width="17"
      height="16"
      x="55"
      y="0"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#FFB000"
        d="M63.418 0l2.498 5.061 5.585.812-4.041 3.94.954 5.562-4.996-2.626-4.996 2.626.954-5.562-4.041-3.94 5.585-.812L63.418 0z"
      ></path>
    </mask>
    <g mask="url(#mask3_2920_42712)">
      <ellipse
        cx="60.478"
        cy="5.666"
        fill="url(#paint3_linear_2920_42712)"
        rx="5.568"
        ry="7.904"
        transform="rotate(36.687 60.478 5.666)"
      ></ellipse>
    </g>
    <path
      fill="#FFB000"
      d="M81.725 0l2.498 5.061 5.585.812-4.042 3.94.954 5.562-4.995-2.626-4.996 2.626.954-5.562-4.042-3.94 5.586-.812L81.725 0z"
    ></path>
    <mask
      id="mask4_2920_42712"
      style={{ maskType: 'alpha' }}
      width="17"
      height="16"
      x="73"
      y="0"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#FFB000"
        d="M81.725 0l2.498 5.061 5.585.812-4.042 3.94.954 5.562-4.995-2.626-4.996 2.626.954-5.562-4.042-3.94 5.586-.812L81.725 0z"
      ></path>
    </mask>
    <g mask="url(#mask4_2920_42712)">
      <ellipse
        cx="78.784"
        cy="5.666"
        fill="url(#paint4_linear_2920_42712)"
        rx="5.568"
        ry="7.904"
        transform="rotate(36.687 78.784 5.666)"
      ></ellipse>
      <path
        fill="#E0E0E0"
        d="M83.931 0H90.30499999999999V16.467H83.931z"
      ></path>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2920_42712"
        x1="5.559"
        x2="5.559"
        y1="-2.238"
        y2="13.57"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.173" stopColor="#FFE96B"></stop>
        <stop offset="0.854" stopColor="#FFB000"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_2920_42712"
        x1="23.866"
        x2="23.866"
        y1="-2.238"
        y2="13.57"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.173" stopColor="#FFE96B"></stop>
        <stop offset="0.854" stopColor="#FFB000"></stop>
      </linearGradient>
      <linearGradient
        id="paint2_linear_2920_42712"
        x1="42.172"
        x2="42.172"
        y1="-2.238"
        y2="13.57"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.173" stopColor="#FFE96B"></stop>
        <stop offset="0.854" stopColor="#FFB000"></stop>
      </linearGradient>
      <linearGradient
        id="paint3_linear_2920_42712"
        x1="60.478"
        x2="60.478"
        y1="-2.238"
        y2="13.57"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.173" stopColor="#FFE96B"></stop>
        <stop offset="0.854" stopColor="#FFB000"></stop>
      </linearGradient>
      <linearGradient
        id="paint4_linear_2920_42712"
        x1="78.784"
        x2="78.784"
        y1="-2.238"
        y2="13.57"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.173" stopColor="#FFE96B"></stop>
        <stop offset="0.854" stopColor="#FFB000"></stop>
      </linearGradient>
    </defs>
  </svg>
)

export const FullFiveStar = ({ className }: SVG) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="85"
    height="17"
    fill="none"
    viewBox="0 0 85 17"
    className={className}
  >
    <path
      fill="#FFB000"
      d="M8.063.189l2.352 4.764 5.257.764-3.804 3.708.898 5.236-4.703-2.472-4.702 2.472.898-5.236L.455 5.717l5.257-.764L8.063.189z"
    ></path>
    <mask
      id="mask0_1146_389"
      style={{ maskType: 'alpha' }}
      width="16"
      height="15"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#FFB000"
        d="M8.063.189l2.352 4.764 5.257.764-3.804 3.708.898 5.236-4.703-2.472-4.702 2.472.898-5.236L.455 5.717l5.257-.764L8.063.189z"
      ></path>
    </mask>
    <g mask="url(#mask0_1146_389)">
      <ellipse
        cx="5.296"
        cy="5.521"
        fill="url(#paint0_linear_1146_389)"
        rx="5.241"
        ry="7.44"
        transform="rotate(36.687 5.296 5.521)"
      ></ellipse>
    </g>
    <path
      fill="#FFB000"
      d="M25.296.189l2.352 4.764 5.257.764L29.1 9.425l.898 5.236-4.703-2.472-4.702 2.472.898-5.236-3.804-3.708 5.257-.764L25.296.189z"
    ></path>
    <mask
      id="mask1_1146_389"
      style={{ maskType: 'alpha' }}
      width="16"
      height="15"
      x="17"
      y="0"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#FFB000"
        d="M25.296.189l2.352 4.764 5.257.764L29.1 9.425l.898 5.236-4.703-2.472-4.702 2.472.898-5.236-3.804-3.708 5.257-.764L25.296.189z"
      ></path>
    </mask>
    <g mask="url(#mask1_1146_389)">
      <ellipse
        cx="22.529"
        cy="5.521"
        fill="url(#paint1_linear_1146_389)"
        rx="5.241"
        ry="7.44"
        transform="rotate(36.687 22.529 5.521)"
      ></ellipse>
    </g>
    <path
      fill="#FFB000"
      d="M42.526.189l2.351 4.764 5.257.764-3.804 3.708.898 5.236-4.702-2.472-4.702 2.472.898-5.236-3.805-3.708 5.258-.764 2.35-4.764z"
    ></path>
    <mask
      id="mask2_1146_389"
      style={{ maskType: 'alpha' }}
      width="17"
      height="15"
      x="34"
      y="0"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#FFB000"
        d="M42.526.189l2.351 4.764 5.257.764-3.804 3.708.898 5.236-4.702-2.472-4.702 2.472.898-5.236-3.805-3.708 5.258-.764 2.35-4.764z"
      ></path>
    </mask>
    <g mask="url(#mask2_1146_389)">
      <ellipse
        cx="39.758"
        cy="5.521"
        fill="url(#paint2_linear_1146_389)"
        rx="5.241"
        ry="7.44"
        transform="rotate(36.687 39.758 5.521)"
      ></ellipse>
    </g>
    <path
      fill="#FFB000"
      d="M59.755.189l2.351 4.764 5.257.764-3.804 3.708.898 5.236-4.702-2.472-4.702 2.472.898-5.236-3.805-3.708 5.258-.764 2.35-4.764z"
    ></path>
    <mask
      id="mask3_1146_389"
      style={{ maskType: 'alpha' }}
      width="16"
      height="15"
      x="52"
      y="0"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#FFB000"
        d="M59.755.189l2.351 4.764 5.257.764-3.804 3.708.898 5.236-4.702-2.472-4.702 2.472.898-5.236-3.805-3.708 5.258-.764 2.35-4.764z"
      ></path>
    </mask>
    <g mask="url(#mask3_1146_389)">
      <ellipse
        cx="56.988"
        cy="5.521"
        fill="url(#paint3_linear_1146_389)"
        rx="5.241"
        ry="7.44"
        transform="rotate(36.687 56.988 5.521)"
      ></ellipse>
    </g>
    <path
      fill="#FFB000"
      d="M76.988.189l2.351 4.764 5.258.764-3.804 3.708.898 5.236-4.703-2.472-4.702 2.472.898-5.236-3.804-3.708 5.257-.764L76.988.189z"
    ></path>
    <mask
      id="mask4_1146_389"
      style={{ maskType: 'alpha' }}
      width="16"
      height="15"
      x="69"
      y="0"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#FFB000"
        d="M76.988.189l2.351 4.764 5.258.764-3.804 3.708.898 5.236-4.703-2.472-4.702 2.472.898-5.236-3.804-3.708 5.257-.764L76.988.189z"
      ></path>
    </mask>
    <g mask="url(#mask4_1146_389)">
      <ellipse
        cx="74.221"
        cy="5.521"
        fill="url(#paint4_linear_1146_389)"
        rx="5.241"
        ry="7.44"
        transform="rotate(36.687 74.22 5.521)"
      ></ellipse>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1146_389"
        x1="5.296"
        x2="5.296"
        y1="-1.919"
        y2="12.961"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.173" stopColor="#FFE96B"></stop>
        <stop offset="0.854" stopColor="#FFB000"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_1146_389"
        x1="22.529"
        x2="22.529"
        y1="-1.919"
        y2="12.961"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.173" stopColor="#FFE96B"></stop>
        <stop offset="0.854" stopColor="#FFB000"></stop>
      </linearGradient>
      <linearGradient
        id="paint2_linear_1146_389"
        x1="39.758"
        x2="39.758"
        y1="-1.919"
        y2="12.961"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.173" stopColor="#FFE96B"></stop>
        <stop offset="0.854" stopColor="#FFB000"></stop>
      </linearGradient>
      <linearGradient
        id="paint3_linear_1146_389"
        x1="56.988"
        x2="56.988"
        y1="-1.919"
        y2="12.961"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.173" stopColor="#FFE96B"></stop>
        <stop offset="0.854" stopColor="#FFB000"></stop>
      </linearGradient>
      <linearGradient
        id="paint4_linear_1146_389"
        x1="74.221"
        x2="74.221"
        y1="-1.919"
        y2="12.961"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.173" stopColor="#FFE96B"></stop>
        <stop offset="0.854" stopColor="#FFB000"></stop>
      </linearGradient>
    </defs>
  </svg>
)

export const Quotation = ({ className }: SVG) => (
  <svg
    viewBox="0 0 42 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M41.5451 6.31983C35.7742 8.61886 32.8622 13.8439 32.8093 21.9951H40.2744V38.3757H24.9471V25.522C24.9471 21.8122 25.1589 18.5988 25.5824 15.8817C26.0589 13.1124 27.2502 10.3693 29.1562 7.65222C31.0621 4.88293 34.0005 2.61002 37.9714 0.833496L41.5451 6.31983ZM16.8466 6.31983C11.0757 8.61886 8.13733 13.8439 8.03144 21.9951H15.576V38.3757H0.248657V25.522C0.248657 21.8122 0.460434 18.5988 0.883987 15.8817C1.36048 13.1124 2.52525 10.3693 4.3783 7.65222C6.28429 4.88293 9.24916 2.61002 13.2729 0.833496L16.8466 6.31983Z"
      fill="#FFE2DF"
    />
  </svg>
)

type BookmarkType = SVG & {
  color?: string
}
export const Bookmark = ({ className, color = '#4AAF50' }: BookmarkType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="130"
    height="26"
    fill="none"
    viewBox="0 0 103 26"
    className={className}
  >
    <g filter="url(#filter0_d_206_17694)">
      <path
        fill={color}
        d="M100.896 21.25l-6.514-10.483L100.896 0H2v21.25h98.896z"
      ></path>
    </g>
    <defs>
      <filter
        id="filter0_d_206_17694"
        width="102.896"
        height="25.25"
        x="0"
        y="0"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="2"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_206_17694"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_206_17694"
          result="shape"
        ></feBlend>
      </filter>
    </defs>
  </svg>
)

export const PhoneOutgoing = ({ className }: SVG) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M17.924 2.617a.997.997 0 00-.215-.322l-.004-.004A.997.997 0 0017 2h-4a1 1 0 100 2h1.586l-3.293 3.293a1 1 0 001.414 1.414L16 5.414V7a1 1 0 102 0V3a.997.997 0 00-.076-.383z" />
      <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
    </svg>
  )
}

export const PhoneCancel = ({ className }: SVG) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
      <path d="M16.707 3.293a1 1 0 010 1.414L15.414 6l1.293 1.293a1 1 0 01-1.414 1.414L14 7.414l-1.293 1.293a1 1 0 11-1.414-1.414L12.586 6l-1.293-1.293a1 1 0 011.414-1.414L14 4.586l1.293-1.293a1 1 0 011.414 0z" />
    </svg>
  )
}

export const WhatsAapp = ({ className }: SVG) => (
  <svg
    role="img"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="currentColor"
  >
    <title>WhatsApp</title>
    <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z" />
  </svg>
)

export const Levels = ({ className }: SVG) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 14 11"
    className={className}
  >
    <path fill="#666" d="M0.954 5.248H4.771V10.592H0.954z"></path>
    <path fill="#666" d="M5.534 2.958H9.350999999999999V10.592H5.534z"></path>
    <path
      stroke="#666"
      d="M10.614 1.168H13.431000000000001V10.091999999999999H10.614z"
    ></path>
  </svg>
)

export const Gender = ({ className }: SVG) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="10" cy="10" r="10" fill="#FFE9E6" />
    <path
      d="M7.79167 10.336H10.0519V10.8673H7.79169V13.6877H7.26044V10.8673H4.92188V10.336H7.26042V8.98195C6.00145 8.81844 5.02909 7.74234 5.02909 6.43922C5.02909 5.02305 6.1775 3.875 7.59413 3.875C9.01077 3.875 10.1592 5.02305 10.1592 6.43922C10.1592 7.78895 9.116 8.89512 7.79167 8.99595V10.336V10.336ZM7.59413 8.42161C8.68931 8.42161 9.57714 7.53406 9.57714 6.43922C9.57714 5.34437 8.68931 4.45684 7.59413 4.45684C6.49894 4.45684 5.61113 5.34439 5.61113 6.43922C5.61113 7.53406 6.49894 8.42161 7.59413 8.42161V8.42161Z"
      fill="#FF5A43"
    />
    <path
      d="M12.1655 11.0078V7.51033L10.0303 9.61333L9.64062 9.19679L12.4635 6.29666L15.0857 9.22802L14.6267 9.61333L12.6968 7.51035V11.014C13.9741 11.1605 14.9662 12.245 14.9662 13.5611C14.9662 14.9771 13.8178 16.125 12.4011 16.125C10.9845 16.125 9.83609 14.9771 9.83609 13.5611C9.83609 12.2245 10.8593 11.1267 12.1655 11.0078V11.0078ZM12.4011 15.5819C13.5177 15.5819 14.4228 14.6771 14.4228 13.5611C14.4228 12.445 13.5177 11.5402 12.4011 11.5402C11.2846 11.5402 10.3794 12.445 10.3794 13.5611C10.3794 14.6771 11.2846 15.5819 12.4011 15.5819H12.4011Z"
      fill="#FF5A43"
    />
  </svg>
)
export const GreenStars = ({ className }: SVG) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 99 19"
    className={className}
  >
    <g clipPath="url(#clip0_4404_4085)">
      <path
        fill="#00B67A"
        d="M18.893.487H.518v18h18.375v-18zM38.799.487H20.424v18h18.375v-18zM58.705.487H40.33v18h18.375v-18zM78.611.487H60.236v18h18.375v-18z"
      ></path>
      <path fill="#DCDCE6" d="M98.518.487H89.33v18h9.188v-18z"></path>
      <path fill="#00B67A" d="M91.75.487H80.143v18H91.75v-18z"></path>
      <path
        fill="#fff"
        d="M9.705 12.618l2.794-.693 1.168 3.525-3.962-2.832zm6.43-4.556h-4.918L9.705 3.525 8.193 8.062h-4.92l3.982 2.813-1.512 4.537 3.98-2.812 2.45-1.725 3.963-2.813zM29.61 12.618l2.796-.693 1.167 3.525-3.962-2.832zm6.432-4.556h-4.919l-1.512-4.537-1.512 4.537h-4.92l3.982 2.813-1.512 4.537L29.63 12.6l2.45-1.725 3.962-2.813zM49.517 12.618l2.795-.693 1.167 3.525-3.962-2.832zm6.431-4.556H51.03l-1.512-4.537-1.512 4.537h-4.92l3.982 2.813-1.512 4.537 3.981-2.812 2.45-1.725 3.962-2.813zM69.423 12.618l2.795-.693 1.168 3.525-3.963-2.832zm6.432-4.556h-4.92l-1.512-4.537-1.512 4.537h-4.919l3.981 2.813-1.512 4.537 3.982-2.812 2.45-1.725 3.962-2.813zM89.33 12.618l2.794-.693 1.168 3.525-3.962-2.832zm6.43-4.556h-4.918L89.33 3.525l-1.512 4.537h-4.92l3.982 2.813-1.512 4.537 3.98-2.812 2.45-1.725 3.963-2.813z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_4404_4085">
        <path
          fill="#fff"
          d="M0 0H98V18H0z"
          transform="translate(.518 .487)"
        ></path>
      </clipPath>
    </defs>
  </svg>
)

export const Quote = ({ className }: SVG) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="30"
    viewBox="0 0 33 30"
    fill="currentColor"
    className={className}
  >
    <path d="M32.731 4.717c-4.483 1.786-6.746 5.846-6.787 12.179h5.8v12.726H19.836v-9.986c0-2.882.165-5.379.494-7.49.37-2.151 1.295-4.283 2.776-6.394 1.48-2.151 3.764-3.917 6.849-5.297l2.776 4.262zm-19.188 0c-4.484 1.786-6.767 5.846-6.85 12.179h5.862v12.726H.647v-9.986c0-2.882.165-5.379.494-7.49.37-2.151 1.275-4.283 2.715-6.394 1.48-2.151 3.784-3.917 6.91-5.297l2.777 4.262z"></path>
  </svg>
)

export const Building = ({ className }: SVG) => (
  <svg
    fill="none"
    stroke="currentColor"
    strokeWidth="1.5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
    ></path>
  </svg>
)

export const FileWithBadge = ({ className }: SVG) => (
  <svg
    viewBox="0 0 41 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g
      clipPath="url(#a)"
      stroke="#fff"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20.506 2.444H5.603c-.988 0-1.936.39-2.635 1.084a3.693 3.693 0 0 0-1.091 2.62v29.629c0 .982.392 1.924 1.091 2.619a3.737 3.737 0 0 0 2.635 1.085h22.354c.989 0 1.936-.39 2.635-1.085a3.693 3.693 0 0 0 1.091-2.619V13.555L20.506 2.444z" />
      <path d="M20.506 2.444v11.11h11.177M24.232 22.814H9.329M26.74 39.154l-1.28 9.586 5.292-3.157 5.293 3.157-1.281-9.596" />
      <path d="M30.752 40.322c4.092 0 7.41-3.297 7.41-7.365s-3.318-7.365-7.41-7.365c-4.092 0-7.41 3.297-7.41 7.365s3.318 7.365 7.41 7.365zM13.054 15.407H9.33" />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          transform="translate(.014 .592)"
          d="M0 0h40.984v50H0z"
        />
      </clipPath>
    </defs>
  </svg>
)

export const MonitorWithInstructor = ({ className }: SVG) => (
  <svg
    className={className}
    viewBox="0 0 54 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47 2H7C4.23858 2 2 4.23858 2 7V32C2 34.7614 4.23858 37 7 37H47C49.7614 37 52 34.7614 52 32V7C52 4.23858 49.7614 2 47 2Z"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9995 47H36.9995"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27 37V47"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.1635 36V33C46.1635 31.4087 45.549 29.8826 44.4552 28.7574C43.3614 27.6321 41.8779 27 40.331 27H28.666C27.1191 27 25.6356 27.6321 24.5418 28.7574C23.448 29.8826 22.8335 31.4087 22.8335 33V36"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.5033 20.33C37.7249 20.33 40.3366 18.0176 40.3366 15.165C40.3366 12.3124 37.7249 10 34.5033 10C31.2816 10 28.6699 12.3124 28.6699 15.165C28.6699 18.0176 31.2816 20.33 34.5033 20.33Z"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.602 14V18.5596H14.204M11.1361 22.3599H5M12.1688 29.0659L11.6418 33L13.8207 31.7044L16 33L15.4726 29.0618"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
